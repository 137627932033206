<template>
  <!-- 抄表数据：选出电表的两个日期，获取这两个日期分别的用电量，其相减就是电表读数 -->
  <div id="app">
    <div class="container">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-date-picker
            style="width: 100%"
            v-model="timePickerValue"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>

        <!-- 公司选择 -->
        <el-col :span="3">
          <el-cascader placeholder="请选择单位"></el-cascader>
        </el-col>

        <!-- 查询按钮 -->
        <el-col :span="1.5">
          <el-button type="primary" @click="getElectricData">查 询</el-button>
        </el-col>

        <!-- 导出excel表格 -->
        <el-col :span="3">
          <download-excel
            types="xls"
            :fields="electricExcelfields"
            :data="tableData"
            name="电表读数"
          >
            <el-button type="primary">导出Excel</el-button>
          </download-excel>
        </el-col>
      </el-row>

      <div class="content">
        <!-- 表格部分 -->
        <el-table
          v-loading="loading"
          element-loading-background="rgba(0, 0, 0, 0.35)"
          @selection-change="handleSelectionChange"
          :data="tableData"
          height="98%"
          border
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column align="center" label="序号" width="60" type="index"></el-table-column>
          <el-table-column
            label="编号"
            prop="coding"
            align="center"
          ></el-table-column>
          <el-table-column
            label="IMEI"
            prop="deviceId"
            align="center"
          ></el-table-column>
          <el-table-column
            label="规格"
            prop="specs"
            align="center"
          ></el-table-column>
          <el-table-column
            label="单位"
            prop="deviceName"
            align="center"
          ></el-table-column>
          <el-table-column
            label="安装位置"
            prop="place"
            align="center"
          ></el-table-column>
          <el-table-column
            label="结束读数"
            prop="nowData"
            align="center"
          ></el-table-column>
          <el-table-column
            label="开始读数"
            prop="beforeData"
            align="center"
          ></el-table-column>
          <el-table-column
            label="期间用电量"
            prop="periodDifference"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <div class="total">
        合计：(Kw/h)<span>{{ totalElectricUse }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// 获取公司id及名称
import companySystem from "@/api/managementApi/CompanyInfo";
// 获取表格数据
import DeviceHistoryValue from "@/api/manageApi/DeviceHistoryValue";

export default {
  components: {},
  props: {},
  data() {
    return {
      // 合计行显示
      totalElectricUse: 0,
      // 导出表格数据
      electricExcelfields: {
        编号: "coding",
        规格: "specs",
        单位: "place",
        安装位置: "deviceName",
        本月读数: "nowData",
        上月读数: "beforeData",
        期间用电量: "periodDifference",
      },
      // 加载页面的展示
      loading: true,
      //页面默认选择时间
      timePickerValue: [],
      // 表格双向绑定数据
      tableData: [],
      // 选择公司下拉框绑定数据
      companyList:[],
    };
  },
  created() {
    // 设置默认时间
    this.setQueryData()
    // 获取表格数据
    this.getElectricData();
  },

  methods: {
    // 获取电表读数
    async getElectricData() {
      let obj = {};
      obj.deviceTypeId = "DXElectricMeter";
      obj.startTime = this.timePickerValue[0];
      obj.endTime = this.timePickerValue[1];
      console.log(this.timePickerValue);
      const { data: res } = await DeviceHistoryValue.getWaterMeterDataAndInfo(
        obj
      );
      if (res.code !== 20000) {
        this.$message.error("请求数据失败！");
      }
      console.log(res.data.list);
      // 调用转化id和名称的库
      // ...
      this.tableData = res.data.list;

      if(this.tableData) this.loading = false
      console.log(this.tableData);
    },
    // 复选框选中添加用水量统计
    handleSelectionChange(val) {
      console.log(val);
      this.totalElectricUse = 0;
      for (let i = 0; i < val.length; i++) {
        this.totalElectricUse += Number(val[i].periodDifference);
      }
    },
    // 设置默认显示日期的方法
    setQueryData(){
      // 格式化方法放到最后
      this.timePickerValue[0] = this.$moment().add(-7, 'day').format('YYYY-MM-DD')
      this.timePickerValue[1] = this.$moment().format('YYYY-MM-DD')
    },
    // 根据查到的公司id转化为名称的回调函数
    getCompanyName(){
      // for(let i = 0;i<123;i++){
      //   for(let j = 0;j<123;j++){
      //     if(a === b){

      //     }
      //   }
      // }
    },

  },
};
</script>

<style lang="scss" scoped>
// 导入表格css
@import "../../../assets/css/tableCss.scss";
// 导入led样式字体
@font-face {
  font-family: led regular;
  src: url("../../../assets/font/ds_digital/DS-DIGI.TTF");
}

.container {
  position: relative;
  box-sizing: border-box;
  padding: 90px 10px 0 10px;
  height: 100%;
  .content {
    height: 90%;
    padding: 10px 0;
    
  }
  .total {
    padding:0 10px ;
    position: absolute;
    bottom: 0;
    height: 60px;
    color: aqua;
    line-height: 60px;
    font-size: 20px;
    width: 95%;
    span{
      font-family: led regular;
      float:right;
      font-size: 40px;
    }
  }
}
</style>